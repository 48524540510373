.loginBox {
  padding: 80px 0;
  text-align: center;
  height: 100%;
  background: wheat;
  position: fixed;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 0.8) 60%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

.loginBox h1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 40px;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-timing-function: ease-in-put;
  transition-timing-function: ease-in-put;
  font-weight: 300;
  margin-top: 0;
}

.loginInputWrapper {
  display: block;
  margin-top: 13px;
}

.loginInput {
  width: 250px;
}

.menuBar {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1px 16px 1px 16px;
  background: #323232;
}

.iconButton {
  height: 46px;
  width: 46px;
}

.iconButtonIcon {
  font-size: 24px !important;
}

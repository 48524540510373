@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body,
#root {
  height: 100%;
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  background: white;
}
